import React, { useState, useEffect } from "react";
import * as Constants from "../../constants/global";
import { connect } from "react-redux";
import CrearArgumentoEdit from "./CrearArgumentoEdit";
import {
  editarPreguntaModulo,
  editarPreguntaModuloEdit,
  eliminarPreguntaModulo,
  crearArgumentoModulo,
  cargarArgumentoModuloEdicion,
  eliminarPreguntaModuloEdit,
  cargarPreguntaModuloEdicion,
} from "../../actions/index";
import axios from "axios";

const CrearPreguntaEdit = (props) => {
  const [descripcion, setDescripcion] = useState();
  const [porcentajeEnModulo, setPorcentajeEnModulo] = useState();
  const [tipo, setTipo] = useState();
  const [estadoPregunta, setEstadoPregunta] = useState(true);
  const [estadoDescripcion, setEstadoDescripcion] = useState(true);
  const [estadoPorcentajeEnModulo, setEstadoPorcentajeEnModulo] =
    useState(true);
  const [estadoTipo, setEstadoTipo] = useState(true);
  var i = 0;
  const classNamePorcentaje = `form-control ${
    estadoPorcentajeEnModulo ? "" : "error"
  }`;
  const classNameDescripcion = `form-control ${
    estadoDescripcion ? "" : "error"
  }`;
  const classNameTipo = `form-control select2 ${estadoTipo ? "" : "error"}`;
  const classNamePregunta = `card direct-chat direct-chat-primary collapsed-card ${
    estadoPregunta ? "" : "error"
  }`;

  useEffect(() => {
    setDescripcion(props.pregunta.titulo);
    setPorcentajeEnModulo(
      props.pregunta.porcentajeEnModulo === ""
        ? ""
        : parseFloat(props.pregunta.porcentajeEnModulo)
    );
    setTipo(props.pregunta.tipo === "" ? 0 : props.pregunta.tipo);
    console.log(props.pregunta.titulo);
    console.log(props.pregunta.porcentajeEnModulo);
    console.log(props.pregunta.tipo);
  }, []);

  const actualizarDatos = (e) => {
    e.preventDefault();
    let params = {
      id: props.pregunta.id,
      titulo: descripcion,
      porcentajeEnModulo: porcentajeEnModulo,
      estado: true,
      terminar: true,
      tipo: "" + tipo,
    };
    props.editarPreguntaModuloEdit(params);
  };

  const habilitarDatos = (e) => {
    e.preventDefault();
    let params = {
      id: props.pregunta.id,
      titulo: descripcion,
      porcentajeEnModulo: porcentajeEnModulo,
      estado: false,
      terminar: false,
      tipo: tipo,
    };
    console.log(params);
    props.editarPreguntaModulo(params);
  };

  const eliminarDatos = (e) => {
    e.preventDefault();
    let params = {
      id: props.pregunta.id,
      titulo: props.pregunta.titulo,
      porcentajeEnModulo: props.pregunta.porcentajeEnModulo,
      estado: props.pregunta.estado,
    };
    props.eliminarPreguntaModuloEdit(params).then((res) => {
      props.cargarPreguntaModuloEdicion(
        localStorage.getItem("codigoEditarModulo")
      );
    });
  };

  const crearArgumento = (e) => {
    e.preventDefault();
    let params = {
      id: Constants.idParametro,
      idPregunta: props.pregunta.id,
      titulo: "",
      porcentajeEnModulo: "",
      estado: false,
    };
    Constants.setIdParametro();
    crearArgumentoModuloUnico(params);
  };

  const crearArgumentoModuloUnico = async (data) => {
    console.log(data);
    console.log(localStorage.getItem("codigoEditarModulo"));
    let url = Constants.URL_BASE + Constants.SPINSERTAARGMODUNICA;
    await axios.post(url, data).then((res) => {
      props.cargarArgumentoModuloEdicion(
        localStorage.getItem("codigoEditarModulo")
      );
    });
  };

  const validarDescripcion = (value) => {
    if (value === "" || value === undefined) {
      setEstadoDescripcion(false);
    } else {
      setEstadoDescripcion(true);
    }
    setDescripcion(value);
  };

  const validarPorcentaje = (value) => {
    console.log(value);
    if (isNaN(value)) {
      setEstadoPorcentajeEnModulo(false);
    } else {
      setEstadoPorcentajeEnModulo(true);
    }
    setPorcentajeEnModulo(value);
  };

  const validarTipo = (value) => {
    console.log(value);
    if (value === "-1") {
      setEstadoTipo(false);
    } else {
      setEstadoTipo(true);
    }
    setTipo(value);
  };

  const verificarPregunta = (e) => {
    var i = 0;
    if (descripcion === "" || descripcion === undefined) {
      setEstadoDescripcion(false);
      i++;
    }
    if (isNaN(porcentajeEnModulo)) {
      setEstadoPorcentajeEnModulo(false);
      i++;
    }
    if (tipo === "-1") {
      setEstadoTipo(false);
      i++;
    }
    console.log(i);
    console.log(tipo);
    if (i === 0) {
      props.argumentosModulos
        .filter(
          (argumento) =>
            argumento.estado === false &&
            argumento.idPregunta === props.pregunta.id
        )
        .map((argumento) => {
          i++;
        });
      console.log(i);
      if (i > 0) {
        setEstadoPregunta(false);
      } else {
        setEstadoPregunta(true);
        actualizarDatos(e);
      }
    }
  };

  return (
    <React.Fragment key={props.pregunta.id}>
      <div className="center">
        <div className={classNamePregunta} style={{ width: "100%" }}>
          <div className="card-header">
            <h3 className="card-title" style={{ color: "black" }}>
              <b>{props.pregunta.id + ". " + props.pregunta.titulo}</b>
            </h3>

            <div className="card-tools">
              <span
                data-toggle="tooltip"
                title={`${i + 1} Respuestas`}
                className="badge badge-primary"
              >
                {props.argumentosModulos.map((argumento) => {
                  if (argumento.idPregunta === props.pregunta.id) {
                    i++;
                  }
                })}
                {i}
              </span>
              <button
                type="button"
                className="btn btn-tool"
                data-card-widget="collapse"
              >
                <i className="fas fa-plus" style={{ color: "GrayText" }}></i>
              </button>
            </div>
          </div>
          <div className="card-body">
            <div
              className="form-group"
              className="mt-3 container"
              style={{ width: "80%" }}
            >
              <label>Descripción Pregunta</label>
              <input
                type="text"
                className={classNameDescripcion}
                placeholder="Descripcion"
                value={descripcion}
                onChange={(e) => validarDescripcion(e.target.value)}
                disabled={props.pregunta.estado}
              />

              <label>Porcentaje Pregunta</label>
              <input
                type="number"
                className={classNamePorcentaje}
                placeholder="Porcentage"
                value={porcentajeEnModulo}
                onChange={(e) => validarPorcentaje(e.target.valueAsNumber)}
                disabled={props.pregunta.estado}
              />

              <label>Tipo Pregunta</label>
              <select
                onChange={(e) => validarTipo(e.target.value)}
                className={classNameTipo}
                style={{ width: "100%" }}
                disabled={props.pregunta.estado}
                text={isNaN(tipo) ? tipo : "-1"}
              >
                <option value="-1">-Seleccionar-</option>
                <option value="1">Pregunta Abierta</option>
                <option value="2">Pregunta Selección Unica</option>
                <option value="3">Pregunta Selección Multiple</option>
              </select>
            </div>
            <div>
              {props.argumentosModulos
                ? props.argumentosModulos
                    .filter(
                      (argumento) => argumento.idPregunta === props.pregunta.id
                    )
                    .map((argumento, index) => {
                      return (
                        <CrearArgumentoEdit
                          argumento={argumento}
                          key={index}
                          idPregunta={props.pregunta.id}
                        />
                      );
                    })
                : null}
            </div>
          </div>

          <div className="card-footer">
            <div className="container margin mt-3">
              <div
                className="d-flex justify-content-center"
                style={{ margin: "2%" }}
              >
                {props.pregunta.estado ? null : (
                  <button
                    onClick={(e) => verificarPregunta(e)}
                    className="btn btn-primary left"
                  >
                    <i className="fas fa-check"></i>
                  </button>
                )}
                {props.pregunta.estado ? (
                  <button
                    onClick={(e) => habilitarDatos(e)}
                    className="btn btn-warning"
                  >
                    <i className="fas fa-user-edit"></i>
                  </button>
                ) : null}
                <button
                  onClick={(e) => eliminarDatos(e)}
                  className="btn btn-danger right"
                >
                  <i className="fas fa-times-circle"></i>
                </button>
                {tipo === "1" ? null : (
                  <button
                    onClick={(e) => crearArgumento(e)}
                    className="btn btn-dark"
                  >
                    <i className="fas fa-plus"></i>
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    argumentosModulos: Object.values(state.argumentosModulos),
  };
};

export default connect(mapStateToProps, {
  editarPreguntaModulo,
  editarPreguntaModuloEdit,
  eliminarPreguntaModulo,
  crearArgumentoModulo,
  cargarArgumentoModuloEdicion,
  eliminarPreguntaModuloEdit,
  cargarPreguntaModuloEdicion,
})(CrearPreguntaEdit);
