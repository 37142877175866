import React, { useEffect, useState } from "react";
import * as Constants from "../../constants/global";
import axios from "axios";
import cierre_ruta_ico from "../../assets/icons-flutter/cierre_ruta_ico.png";
import efectividad_acumulada_ico from "../../assets/icons-flutter/efectividad_acumulada_ico.png";
import efectividad_ico from "../../assets/icons-flutter/efectividad_ico.png";
import EfectividadAcumulada from "../../assets/icons-flutter/EfectividadAcumulada.png";
import evaluacion_ico from "../../assets/icons-flutter/evaluacion_ico.png";
import indicadores_ico from "../../assets/icons-flutter/indicadores_ico.png";
import resultados_ico from "../../assets/icons-flutter/resultados_ico.png";
import rutero_ico from "../../assets/icons-flutter/rutero_ico.png";

const FiltroRoles = (props) => {
  const [opciones, setOpciones] = useState([]);
  const [destino, setDestino] = useState("-1");

  const roles = opciones.map((opcion) => {
    return <option value={opcion.codigo}>{opcion.rol}</option>;
  });

  const cargarOpciones = async () => {
    let url = Constants.URL_BASE + Constants.SPLISTAROLES;
    console.log(url);
    await axios.get(url).then((res) => {
      var listaOpciones = res.data;
      console.log(listaOpciones);
      setOpciones(listaOpciones);
      console.log(res);
    });
  };

  useEffect(() => {
    cargarOpciones();
  }, []);

  useEffect(() => {
    console.log(destino);
  }, [destino]);

  return (
    <div class="card card-secondary" style={{width:'100%'}}>
      <div class="card-header">
        <h3 class="card-title">Creación Menus Movil</h3>
      </div>
      <div class="card-body">
        <form role="form">
          <div class="row" >
            <div class="col-sm-6">
              <div class="form-group">
                <label>Destino</label>
                <select
                  class="custom-select"
                  value={destino}
                  onChange={(e) => setDestino(e.target.value)}
                >
                  <option value="-1">-Seleccionar-</option>
                  <option value="1">Cliente</option>
                  <option value="2">Vendedor</option>
                </select>
              </div>
            </div>
            <div class="col-sm-6">
              <div class="form-group">
                <label>Descripción</label>
                <input
                  type="text"
                  class="form-control"
                  placeholder="Enter ..."
                />
              </div>
            </div>
          </div>
          <table style={{width:'100%'}}>
            <tr>
              <td class="col-sm-3">
                <input
                  class="form-check-input me-1 check-box"
                  type="checkbox"
                  // value={menuRoles.codigo}
                  aria-label="..."
                  // checked={menuRoles.activo === 1 ? true : false}
                  // onClick={(e) => actualizarMenuRol(e.target.value)}
                />
              </td>
              <td class="col-sm-3">
                <img src={cierre_ruta_ico}></img>
              </td>
              <td class="col-sm-3">
                <input
                  class="form-check-input me-1 check-box"
                  type="checkbox"
                  // value={menuRoles.codigo}
                  aria-label="..."
                  // checked={menuRoles.activo === 1 ? true : false}
                  // onClick={(e) => actualizarMenuRol(e.target.value)}
                />
              </td>
              <td class="col-sm-3">
                <img src={efectividad_acumulada_ico}></img>
              </td>
            </tr>
            <tr>
              <td class="col-sm-3">
                <input
                  class="form-check-input me-1 check-box"
                  type="checkbox"
                  // value={menuRoles.codigo}
                  aria-label="..."
                  // checked={menuRoles.activo === 1 ? true : false}
                  // onClick={(e) => actualizarMenuRol(e.target.value)}
                />
              </td>
              <td class="col-sm-3">
                <img src={efectividad_ico}></img>
              </td>
              <td class="col-sm-3">
                <input
                  class="form-check-input me-1 check-box"
                  type="checkbox"
                  // value={menuRoles.codigo}
                  aria-label="..."
                  // checked={menuRoles.activo === 1 ? true : false}
                  // onClick={(e) => actualizarMenuRol(e.target.value)}
                />
              </td>
              <td class="col-sm-3">
                <img src={EfectividadAcumulada}></img>
              </td>
            </tr>
            <tr>
              <td class="col-sm-3">
                <input
                  class="form-check-input me-1 check-box"
                  type="checkbox"
                  // value={menuRoles.codigo}
                  aria-label="..."
                  // checked={menuRoles.activo === 1 ? true : false}
                  // onClick={(e) => actualizarMenuRol(e.target.value)}
                />
              </td>
              <td class="col-sm-3">
                <img src={evaluacion_ico}></img>
              </td>
              <td class="col-sm-3">
                <input
                  class="form-check-input me-1 check-box"
                  type="checkbox"
                  // value={menuRoles.codigo}
                  aria-label="..."
                  // checked={menuRoles.activo === 1 ? true : false}
                  // onClick={(e) => actualizarMenuRol(e.target.value)}
                />
              </td>
              <td class="col-sm-3">
                <img src={indicadores_ico}></img>
              </td>
            </tr>
            <tr>
              <td class="col-sm-3">
                <input
                  class="form-check-input me-1 check-box"
                  type="checkbox"
                  // value={menuRoles.codigo}
                  aria-label="..."
                  // checked={menuRoles.activo === 1 ? true : false}
                  // onClick={(e) => actualizarMenuRol(e.target.value)}
                />
              </td>
              <td class="col-sm-3">
                <img src={resultados_ico}></img>
              </td>
              <td class="col-sm-3">
                <input
                  class="form-check-input me-1 check-box"
                  type="checkbox"
                  // value={menuRoles.codigo}
                  aria-label="..."
                  // checked={menuRoles.activo === 1 ? true : false}
                  // onClick={(e) => actualizarMenuRol(e.target.value)}
                />
              </td>
              <td class="col-sm-3">
                <img src={rutero_ico}></img>
              </td>
            </tr>
          </table>
          <div class="form-group checkbox">
            <div class="custom-control custom-switch">
              <input
                type="checkbox"
                class="custom-control-input"
                id="customSwitch1"
              />
              <label class="custom-control-label" for="customSwitch1">
                Activar
              </label>
            </div>
          </div>
          {destino === "1" ? <InputValor /> : null}
          <button class="btn btn-danger boton" type="submit">
            <b>Crear Menu</b>
          </button>
        </form>
      </div>
    </div>
  );
};

export default FiltroRoles;

const InputValor = () => (
  <div class="col-sm-12">
    <div class="form-group">
      <label>Valor</label>
      <input type="number" class="form-control" placeholder="Enter ..." />
    </div>
  </div>
);
