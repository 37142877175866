import React, { useState } from "react";
import DatePicker from "react-date-picker";
import history from "../../history";
import { withRouter } from "react-router-dom";
import {
  cargarPreguntaModuloEdicion,
  cargarArgumentoModuloEdicion,
} from "../../actions/index";
import { connect } from "react-redux";
const FormularioModuloEditar = (props) => {
  const classNameTitle = `form-control ${props.estadoTitulo ? "" : "error"}`;
  const classNameDescripcion = `form-control ${
    props.estadoDescripcion ? "" : "error"
  }`;

  const gestionEncuesta = (e) => {
    e.preventDefault();
    console.log("aca");
    localStorage.setItem("codigoEditarModulo", props.moduloCargado.codigo);
    props.cargarPreguntaModuloEdicion(props.moduloCargado.codigo);
    props.cargarArgumentoModuloEdicion(props.moduloCargado.codigo);
    props.history.push("/surveyEdit");
  };

  return (
    <React.Fragment>
      <form>
        <div className="card-body">
          <div className="form-group">
            <label className="d-flex justify-content-start">Titulo</label>
            <input
              type="text"
              className={classNameTitle}
              placeholder="Escribe el titulo"
              value={props.titulo}
              onChange={(e) => props.validarTitulo(e.target.value)}
            />
          </div>
          <div className="form-group">
            <label className="d-flex justify-content-start">Descripcion</label>
            <input
              type="text"
              className={classNameDescripcion}
              placeholder="Escribe la descripcion"
              value={props.descripcion}
              onChange={(e) => props.validarDescripcion(e.target.value)}
            />
          </div>
          {/* <div className="form-group">
                <label className="d-flex justify-content-start">
                  Fecha Inicial
                </label>
                <DatePicker
                  onChange={props.setFechaInicial}
                  value={props.fechaInicial}
                  style={{ width: "80%" }}
                />
              </div>
              <div className="form-group">
                <label className="d-flex justify-content-start">
                  Fecha Final
                </label>
                <DatePicker
                  onChange={props.setFechaFinal}
                  value={props.fechaFinal}
                  style={{ width: "80%" }}
                />
              </div> */}
        </div>
        <div className="card-footer d-flex justify-content-around">
          <button
            type="submit"
            className="btn btn-primary center"
            onClick={props.crearModulo}
          >
            Editar
          </button>
          <button
            type="submit"
            className="btn btn-primary center"
            onClick={(e) => gestionEncuesta(e)}
          >
            Gestion
          </button>
          <button
            type="submit"
            className="btn btn-danger center"
            onClick={props.cancelarCrearModulo}
          >
            Cancelar
          </button>
        </div>
      </form>
    </React.Fragment>
  );
};

export default connect(null, {
  cargarPreguntaModuloEdicion,
  cargarArgumentoModuloEdicion,
})(withRouter(FormularioModuloEditar));
