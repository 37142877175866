import _ from "lodash";
import initialState from "./initialState";

import {
  EDITAR_ARGUMENTO_MODULO,
  ELIMINAR_ARGUMENTO_MODULO,
  CREAR_ARGUMENTO,
  ELIMINAR_ARGUMENTOS_MODULO,
  LIMPIAR_ARGUMENTO_MODULO,
  CARGAR_ARGUMENTO_EDICION,
} from "../../constants/global";
export default (state = initialState.argumentos, action) => {
  switch (action.type) {
    case CREAR_ARGUMENTO:
      console.log(action.payload);
      return [...state, action.payload];
    case CARGAR_ARGUMENTO_EDICION:
      return [...state, action.payload];
    case EDITAR_ARGUMENTO_MODULO:
      console.log("editar");
      console.log(action.payload);
      state = state.map((content, i) =>
        content.id === action.payload.id ? action.payload : content
      );
      return state;
    case ELIMINAR_ARGUMENTO_MODULO:
      console.log(action.payload);
      state = state.filter((item) => item.id !== action.payload.id);
      return state;
    case ELIMINAR_ARGUMENTOS_MODULO:
      console.log(action.payload);
      state = state.filter((item) => item.idPregunta !== action.payload.id);
      return state;
    case LIMPIAR_ARGUMENTO_MODULO:
      state = [];
      return state;
    default:
      return state;
  }
};
