import React, { useEffect, useState } from "react";
import * as Constants from "../../constants/global";
import axios from "axios";

const FiltroConsolidado = (props) => {
  const [opciones, setOpciones] = useState([]);
  const cargarOpciones = async () => {
    let url = Constants.URL_BASE + Constants.SPLISTAMODULOSELECCIONAR;
    console.log(url);
    await axios.get(url).then((res) => {
      var listaOpciones = res.data;
      console.log(listaOpciones);
      setOpciones(listaOpciones);
      console.log(res);
    });
  };

  useEffect(() => {
    cargarOpciones();
  }, []);

  return (
    <React.Fragment>
      <div class="card-header blue">
        <h3 class="card-title">Modulo</h3>
      </div>
      <div class="card">
        <div className="card-body">
          <div className="form-group" className="mt-3 container">
            <label>Modulo</label>
            <select
              style={{ width: "100%" }}
              onChange={(e) => props.setCodModulo(e.target.value)}
            >
              {opciones.map((opcion) => {
                return <option value={opcion.codigo}>{opcion.titulo}</option>;
              })}
            </select>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default FiltroConsolidado;
