import * as Constants from "../../constants/global";
import React, { useEffect, useState } from "react";
import axios from "axios";
const TablaConsolidadoScreen = (props) => {
  console.log(props.resultado);

  return (
    <div class="card card-primary mt-3">
      <div class="card-header blue">
        <h3 class="card-title">{props.resultado.descripcion}</h3>
      </div>
      {/* <section class="content"> */}
      {/* <div class="row"> */}
      {/* <div class="col-12"> */}
      <div class="card">
        <div class="card-body">
          <table
            id="example2"
            class="table table-bordered table-hover"
            style={{ tableLayout: "fixed" }}
          >
            <tbody>
              <th>Codigo Pregunta</th>
              <th>Descripción</th>
              <th>Codigo Respuesta</th>
              <th>Respuesta</th>
              <th>Cantidad</th>
              {props.listaConsolidado
                ? props.listaConsolidado.filter(lista=>lista.codigoPregunta===props.resultado.codigoPregunta).map((modulo) => {
                    return (
                      <tr>
                        <td>{modulo.codigoPregunta}</td>
                        <td>{modulo.descripcion}</td>
                        <td>{modulo.codigoRespuesta}</td>
                        <td>{modulo.respuesta}</td>
                        <td>{modulo.cantidad}</td>
                      </tr>
                    );
                  })
                : null}
            </tbody>
          </table>
        </div>
      </div>
      {/* </div> */}
      {/* </div> */}
      {/* </section> */}
    </div>
  );
};

export default TablaConsolidadoScreen;
