import React, { useEffect, useState } from "react";
import * as Constants from "../../constants/global";
import axios from "axios";

const FiltroRoles = (props) => {
  const [opciones, setOpciones] = useState([]);

  const roles = opciones.map((opcion) => {
    return <option value={opcion.codigo}>{opcion.rol}</option>;
  });

  const cargarOpciones = async () => {
    let url = Constants.URL_BASE + Constants.SPLISTAROLES;
    console.log(url);
    await axios.get(url).then((res) => {
      var listaOpciones = res.data;
      console.log(listaOpciones);
      setOpciones(listaOpciones);
      console.log(res);
    });
  };

  useEffect(() => {
    cargarOpciones();
  }, []);

  return (
    <div class="col-md-12">
      <div class="card-header blue">
        <h3 class="card-title">Rol</h3>
      </div>
      <div class="card">
        <div className="card-body">
          <div className="form-group" className="mt-3 container">
            <label>Seleccionar Rol</label>
            <select
              style={{ width: "100%" }}
              onChange={(e) => props.setRol(e.target.value)}
            >
              {roles ? roles : null}
            </select>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FiltroRoles;
