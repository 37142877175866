export const URL_BASE =
  /*PRD*/
  "https://emartwebapi.celuwebdev.com/RAMO_SUPERVISORES/api/";
/*QAS*/
// "https://mercadeo.celuwebdev.com/ApiRamoCanalAlternativo/";
/*LOCAL*/
// "https://localhost:5001/api/";

export const SPLOGIN = "RamoSupervisoresWeb/login";
export const SPINSERTARPREGMODUNICA =
  "RamoSupervisoresWeb/insertarPreguntasModuloUnica";
export const SPINSERTAARGMODUNICA =
  "RamoSupervisoresWeb/insertarArgumentosModuloUnica";
export const SPINSERTARPREGMOD = "RamoSupervisoresWeb/insertarPreguntasModulo";
export const SPINSERTARPARMOD = "RamoSupervisoresWeb/insertarParametroModulo";
export const SPINSERTARMOD = "RamoSupervisoresWeb/insertarModulo";
export const SPEDITARMOD = "RamoSupervisoresWeb/editarModulo";
export const SPELIMINARMOD = "RamoSupervisoresWeb/eliminarModulo";
export const SPCONSOLIDARMOD = "RamoSupervisoresWeb/consolidarModulo";
export const SPMODULOS = "RamoSupervisoresWeb/listaModulos";
export const SPMENUS = "RamoSupervisoresWeb/listaMenus";
export const SPSUBMENUS = "RamoSupervisoresWeb/listaSubMenus";
export const SPLISTACONSOLIDADO = "RamoSupervisoresWeb/consolidadoModulos";
export const SPLISTAMODULOSELECCIONAR =
  "RamoSupervisoresWeb/listaModulosSeleccionar";
export const SPLISTAPREGUNTACONSOLIDADO =
  "RamoSupervisoresWeb/listaPreguntasconsolidadoModulos";
  export const SPLISTAROLES =
  "RamoSupervisoresWeb/listaRoles";
  export const SPLISTAMENUSROL =
  "RamoSupervisoresWeb/listaMenusRol";
  export const SPUPDATEMENUSROL =
  "RamoSupervisoresWeb/updateMenusRol";
  export const SPDELETEPREGUNTA =
  "RamoSupervisoresWeb/eliminarPreguntaModuloUnica";
export let idPregunta = 0;
export const setIdPregunta = () => {
  idPregunta++;
};

export let idParametro = 0;
export const setIdParametro = () => {
  idParametro++;
};

export const CREAR_ARGUMENTO = "CREAR_ARGUMENTO";
export const EDITAR_ARGUMENTO_MODULO = "EDITAR_ARGUMENTO_MODULO";
export const ELIMINAR_ARGUMENTO_MODULO = "ELIMINAR_ARGUMENTO_MODULO";
export const ELIMINAR_ARGUMENTOS_MODULO = "ELIMINAR_ARGUMENTOS_MODULO";
export const CARGAR_PREGUNTA_MODULO = "CARGAR_PREGUNTA_MODULO";
export const EDITAR_PREGUNTA_MODULO = "EDITAR_PREGUNTA_MODULO";
export const ELIMINAR_PREGUNTA_MODULO = "ELIMINAR_PREGUNTA_MODULO";
export const CARGAR_PREGUNTA_EDICION_MODULO = "CARGAR_PREGUNTA_EDICION_MODULO";
export const LIMPIAR_ARGUMENTO_MODULO = "LIMPIAR_ARGUMENTO_MODULO";
export const LIMPIAR_PREGUNTA_MODULO = "LIMPIAR_PREGUNTA_MODULO";
export const CARGAR_MENUS = "CARGAR_MENUS";
export const CARGAR_SUB_MENUS = "CARGAR_SUB_MENUS";
export const LIMPIAR_MENU_MODULO = "LIMPIAR_MENU_MODULO";
export const LIMPIAR_SUBMENU_MODULO = "LIMPIAR_SUBMENU_MODULO";
export const CARGAR_ARGUMENTO_EDICION = "CARGAR_ARGUMENTO_EDICION";
export const SIGN_IN = "SIGN_IN";
export const SIGN_OUT = "SIGN_OUT";
export const CARGAR_PREGUNTA_MODULO_EDIT = "CARGAR_PREGUNTA_MODULO_EDIT";

export const convert112 = (dateString) => {
  var year = dateString.substring(0, 4);
  var month = dateString.substring(4, 6);
  var day = dateString.substring(6, 8);

  var date = new Date(year, month - 1, day);
  var currentDate = new Date();
  return currentDate;
};
