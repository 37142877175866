import {
  CARGAR_PREGUNTA_MODULO,
  CREAR_ARGUMENTO,
  EDITAR_PREGUNTA_MODULO,
  ELIMINAR_PREGUNTA_MODULO,
  EDITAR_ARGUMENTO_MODULO,
  ELIMINAR_ARGUMENTO_MODULO,
  ELIMINAR_ARGUMENTOS_MODULO,
  LIMPIAR_PREGUNTA_MODULO,
  LIMPIAR_ARGUMENTO_MODULO,
  CARGAR_MENUS,
  CARGAR_SUB_MENUS,
  LIMPIAR_MENU_MODULO,
  LIMPIAR_SUBMENU_MODULO,
  CARGAR_ARGUMENTO_EDICION,
  SIGN_OUT,
  SIGN_IN,
  SPDELETEPREGUNTA,
  CARGAR_PREGUNTA_MODULO_EDIT
} from "../constants/global";
import ramo from "../apis/ramo";

export const signIn = (data) => {
  return {
    type: SIGN_IN,
    payload: { data },
  };
};

export const signOut = () => {
  return {
    type: SIGN_OUT,
  };
};

export const cargarPreguntaModulo = (data) => {
  return async (dispatch) => {
    dispatch({ type: CARGAR_PREGUNTA_MODULO, payload: data });
  };
};

export const cargarPreguntaModuloEdicion = (codigoModulo) => {
  return async (dispatch) => {
    dispatch({ type: LIMPIAR_PREGUNTA_MODULO });
    const response = await ramo.get(
      "ramosupervisoresweb/cargarPreguntasModulo?codigoModulo=" + codigoModulo
    );
    response.data.map((dato) => {
      dispatch({ type: CARGAR_PREGUNTA_MODULO_EDIT, payload: dato });
    });
  };
};

export const editarPreguntaModulo = (data) => {
  return async (dispatch) => {
    dispatch({ type: EDITAR_PREGUNTA_MODULO, payload: data });
  };
};

export const editarPreguntaModuloEdit = (data) => {
  console.log(data);
  return async (dispatch) => {
    console.log("si entra");
    delete data.terminar;
    data.porcentajeEnModulo = data.porcentajeEnModulo.toString();
    console.log(data);
    const response = await ramo.post(
      "ramosupervisoresweb/editarPreguntaModulo",
      data
    );
    if (response) {
      dispatch({ type: EDITAR_PREGUNTA_MODULO, payload: data });
    }
  };
};

export const eliminarPreguntaModulo = (data) => {
  return async (dispatch) => {
    dispatch({ type: ELIMINAR_PREGUNTA_MODULO, payload: data });
    dispatch({ type: ELIMINAR_ARGUMENTOS_MODULO, payload: data });
  };
};

export const eliminarPreguntaModuloEdit = (data) => {
  return async (dispatch) => {
    const response = await ramo.post(SPDELETEPREGUNTA, data);
  };
};

export const crearArgumentoModulo = (data) => {
  return async (dispatch) => {
    dispatch({ type: CREAR_ARGUMENTO, payload: data });
  };
};

export const cargarArgumentoModuloEdicion = (codigoModulo) => {
  return async (dispatch) => {
    const response = await ramo.get(
      "ramosupervisoresweb/cargarArgumentosModulo?codigoModulo=" + codigoModulo
    );
    console.log(response.data);
    dispatch({ type: LIMPIAR_ARGUMENTO_MODULO });
    response.data.map((dato) => {
      dispatch({ type: CARGAR_ARGUMENTO_EDICION, payload: dato });
    });
  };
};

export const editarArgumentoModulo = (data) => {
  return async (dispatch) => {
    dispatch({ type: EDITAR_ARGUMENTO_MODULO, payload: data });
  };
};

export const editarArgumentoModuloEdit = (data) => {
  return async (dispatch) => {
    console.log(data);

    const response = await ramo.post(
      "ramosupervisoresweb/editarArgumentoModulo",
      data
    );
    if (response) {
      dispatch({ type: EDITAR_ARGUMENTO_MODULO, payload: data });
    }
  };
};

export const eliminarArgumentoModulo = (data) => {
  return async (dispatch) => {
    console.log(data);

    const response = await ramo.post(
      "ramosupervisoresweb/eliminarArgumentosModuloUnica",
      data
    );
    if (response) {
      dispatch({ type: ELIMINAR_ARGUMENTO_MODULO, payload: data });
    }
  };
};

export const limpiarDatos = () => {
  return async (dispatch) => {
    dispatch({ type: LIMPIAR_ARGUMENTO_MODULO });
    dispatch({ type: LIMPIAR_PREGUNTA_MODULO });
  };
};

export const limpiarMenusSubMenus = () => {
  return async (dispatch) => {
    dispatch({ type: LIMPIAR_MENU_MODULO });
    dispatch({ type: LIMPIAR_SUBMENU_MODULO });
  };
};

export const cargarMenus = () => {
  console.log("menus");
  return async (dispatch) => {
    const response = await ramo.get("ramosupervisoresweb/listaMenus");
    console.log(response.data);
    response.data.map((dato) => {
      dispatch({ type: CARGAR_MENUS, payload: dato });
    });
  };
};

export const cargarSubMenus = () => {
  console.log("submenus");
  return async (dispatch) => {
    const response = await ramo.get("ramosupervisoresweb/listaSubMenus");
    console.log(response.data);
    response.data.map((dato) => {
      dispatch({ type: CARGAR_SUB_MENUS, payload: dato });
    });
  };
};
