import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import {
  editarArgumentoModulo,
  eliminarArgumentoModulo,
} from "../../actions/index";
import { connect } from "react-redux";

const CrearArgumento = (props) => {
  const [descripcion, setDescripcion] = useState();
  const [porcentajeEnModulo, setPorcentajeEnModulo] = useState();
  const [estadoDescripcion, setEstadoDescripcion] = useState(true);
  const [estadoPorcentajeEnModulo, setEstadoPorcentajeEnModulo] =
    useState(true);
  const classNamePorcentaje = `form-control mt-3 ${
    estadoPorcentajeEnModulo ? "" : "error"
  }`;
  const classNameDescripcion = `form-control mt-3 ${
    estadoDescripcion ? "" : "error"
  }`;

  const actualizarDatos = (e) => {
    console.log("actualizar");
    e.preventDefault();
    let params = {
      id: props.argumento.id,
      idPregunta: props.argumento.idPregunta,
      titulo: descripcion,
      porcentajeEnModulo: porcentajeEnModulo,
      estado: true,
    };
    props.editarArgumentoModulo(params);
    toast.success("se actualizo la respuesta")
  };

  const habilitarDatos = (e) => {
    e.preventDefault();
    let params = {
      id: props.argumento.id,
      idPregunta: props.argumento.idPregunta,
      titulo: descripcion,
      porcentajeEnModulo: porcentajeEnModulo,
      estado: false,
    };
    props.editarArgumentoModulo(params);
  };

  const eliminarDatos = (e) => {
    e.preventDefault();
    let params = {
      id: props.argumento.id,
      idPregunta: props.argumento.idPregunta,
      titulo: props.argumento.titulo,
      porcentajeEnModulo: props.argumento.porcentajeEnModulo,
      estado: props.argumento.estado,
    };
    props.eliminarArgumentoModulo(params);
    toast.success("se elimino la respuesta")
  };

  const activarParametros = (e) => {
    e.preventDefault();
    console.log("activar parametros");
  };

  const validarDescripcion = (value) => {
    if (value === "" || value === undefined) {
      setEstadoDescripcion(false);
      toast.error("Falta la descripción")
    } else {
      setEstadoDescripcion(true);
    }
    setDescripcion(value);
  };

  const validarPorcentaje = (value) => {
    if (value === "" || value === undefined) {
      setEstadoPorcentajeEnModulo(false);
      toast.error("Falta el porcentaje")
    } else {
      setEstadoPorcentajeEnModulo(true);
    }
    setPorcentajeEnModulo(value);
  };

  const verificarArgumento = (e) => {
    var i = 0;
    if (descripcion === "" || descripcion === undefined) {
      setEstadoDescripcion(false);
      i++;
    }
    if (porcentajeEnModulo === "" || porcentajeEnModulo === undefined) {
      setEstadoPorcentajeEnModulo(false);
      i++;
    }
    if (i === 0) {
      toast.success("se agrego la respuesta")
      actualizarDatos(e);
    }
  };

  return (
    <React.Fragment key={props.argumento.id}>
      <div className="card card-primary center collapsed-card" style={{ margin: "18px" }}>
        <div className="card-header">
          <h4 className="card-title" style={{ color: "black" }}>
            <b>Parametro {props.argumento.id + 1}</b>
          </h4>

          <div className="card-tools">
            <span data-toggle="tooltip" className="badge badge-primary">
              1
            </span>
            <button
              type="button"
              className="btn btn-tool"
              data-card-widget="collapse"
            >
              <i
                className="fas fa-angle-down"
                style={{ color: "GrayText" }}
              ></i>
            </button>
          </div>
        </div>
        <div className="card-body centrar">
          <input
            type="text"
            className={classNameDescripcion}
            placeholder="Descripcion"
            onChange={(e) => validarDescripcion(e.target.value)}
            disabled={props.argumento.estado}
            text={descripcion}
          />
          <input
            type="number"
            className={classNamePorcentaje}
            placeholder="Porcentage"
            text={porcentajeEnModulo}
            onChange={(e) => validarPorcentaje(e.target.value)}
            disabled={props.argumento.estado}
          />

          <div
            className="mt-3 d-flex justify-content-center"
            style={{ margin: "2%" }}
          >
            {props.argumento.estado ? null : (
              <button
                onClick={(e) => verificarArgumento(e)}
                className="btn btn-primary"
              >
                <i className="fas fa-check"></i>
              </button>
            )}
            {props.argumento.estado ? (
              <button
                onClick={(e) => habilitarDatos(e)}
                className="btn btn-warning"
              >
                <i className="fas fa-user-edit"></i>
              </button>
            ) : null}
            <button
              onClick={(e) => eliminarDatos(e)}
              className="btn btn-danger"
            >
              <i className="fas fa-times-circle"></i>
            </button>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default connect(null, {
  editarArgumentoModulo,
  eliminarArgumentoModulo,
})(CrearArgumento);
