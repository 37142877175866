import React, { useState } from "react";
import * as Constants from "../../constants/global";
import {
  cargarPreguntaModulo,
  cargarPreguntaModuloEdicion,
} from "../../actions/index";
import { connect } from "react-redux";
import axios from "axios";
const FormularioPreguntasEdit = (props) => {
  const [tituloPregunta, setTituloPregunta] = useState();

  const crearPregunta = (e) => {
    console.log("aca?")
    e.preventDefault();
    console.log(localStorage.getItem("codigoEditarModulo"));
    let data = {
      id: Constants.idPregunta,
      codModulo: localStorage.getItem("codigoEditarModulo"),
      titulo: "",
      porcentajeEnModulo: "",
      estado: false,
    };

    insertarPreguntas(data);
  };

  const insertarPreguntas = async (data) => {
    console.log(data);
    let url = Constants.URL_BASE + Constants.SPINSERTARPREGMODUNICA;
    await axios.post(url, data).then((res) => {
      console.log("miadsadsa")
      props.cargarPreguntaModuloEdicion(
        localStorage.getItem("codigoEditarModulo")
      );
    });
  };

  return (
    <React.Fragment>
      <div className="card card-primary mt-3">
        <div className="card-header blue">
          <h3 className="card-title">Creación Modulo</h3>
        </div>
        <div className="navbar principal"></div>
        <div className="contenedor" style={{ marginBottom: "4%" }}>
          <div className="text-center"></div>
          <form className="ui form mt-5 d-flex justify-content-around">
            <button
              onClick={(e) => crearPregunta(e)}
              className="btn btn-primary"
            >
              Agregar Pregunta
            </button>
            <button
              //   onClick={props.cancelarCrearModulo}
              className="btn btn-warning"
            >
              Cancelar
            </button>
          </form>
          {/* <div classname="ui list">
        {props.preguntasModulos
          ? props.preguntasModulos.map((pregunta, index) => {
              return <CrearPregunta pregunta={pregunta} key={index} />;
            })
          : null}
      </div> */}
        </div>
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    preguntasModulos: Object.values(state.preguntasModulos),
  };
};

export default connect(mapStateToProps, {
  cargarPreguntaModulo,
  cargarPreguntaModuloEdicion,
})(FormularioPreguntasEdit);
