import React, { useState, useEffect } from "react";
import * as Constants from "../../constants/global";
import { connect } from "react-redux";
import CrearArgumento from "./CrearArgumento";
import {
  editarPreguntaModulo,
  eliminarPreguntaModulo,
  crearArgumentoModulo,
} from "../../actions/index";

const CrearPregunta = (props) => {
  const [descripcion, setDescripcion] = useState();
  const [porcentajeEnModulo, setPorcentajeEnModulo] = useState();
  const [estadoPregunta, setEstadoPregunta] = useState(true);
  const [tipo, setTipo] = useState("-1");

  const [estadoDescripcion, setEstadoDescripcion] = useState(true);
  const [estadoPorcentajeEnModulo, setEstadoPorcentajeEnModulo] =
    useState(true);
  const [estadoTipo, setEstadoTipo] = useState(true);
  var i = 0;

  const classNamePorcentaje = `form-control ${
    estadoPorcentajeEnModulo ? "" : "error"
  }`;
  const classNameDescripcion = `form-control ${
    estadoDescripcion ? "" : "error"
  }`;
  const classNameTipo = `form-control select2 ${estadoTipo ? "" : "error"}`;
  const classNamePregunta = `card direct-chat direct-chat-primary collapsed-card ${
    estadoPregunta ? "" : "error"
  }`;

  const actualizarDatos = (e) => {
    e.preventDefault();
    let params = {
      id: props.pregunta.id,
      titulo: descripcion,
      porcentajeEnModulo: porcentajeEnModulo,
      estado: true,
      tipo: tipo,
    };
    props.editarPreguntaModulo(params);
  };

  const habilitarDatos = (e) => {
    e.preventDefault();
    let params = {
      id: props.pregunta.id,
      titulo: descripcion,
      porcentajeEnModulo: porcentajeEnModulo,
      estado: false,
      terminar: false,
    };
    props.editarPreguntaModulo(params);
  };

  const eliminarDatos = (e) => {
    e.preventDefault();
    let params = {
      id: props.pregunta.id,
      titulo: props.pregunta.titulo,
      porcentajeEnModulo: props.pregunta.porcentajeEnModulo,
      estado: props.pregunta.estado,
    };
    props.eliminarPreguntaModulo(params);
  };

  const crearArgumento = (e) => {
    e.preventDefault();
    let params = {
      id: Constants.idParametro,
      idPregunta: props.pregunta.id,
      titulo: "",
      porcentajeEnModulo: "",
      estado: false,
    };
    Constants.setIdParametro();
    props.crearArgumentoModulo(params);
  };

  const validarDescripcion = (value) => {
    if (value === "" || value === undefined) {
      setEstadoDescripcion(false);
    } else {
      setEstadoDescripcion(true);
    }
    setDescripcion(value);
  };

  const validarPorcentaje = (value) => {
    if (value === "" || value === undefined) {
      setEstadoPorcentajeEnModulo(false);
    } else {
      setEstadoPorcentajeEnModulo(true);
    }
    setPorcentajeEnModulo(value);
  };

  const verificarPregunta = (e) => {
    var i = 0;
    if (descripcion === "" || descripcion === undefined) {
      setEstadoDescripcion(false);
      i++;
    }
    if (porcentajeEnModulo === "" || porcentajeEnModulo === undefined) {
      setEstadoPorcentajeEnModulo(false);
      i++;
    }
    if (tipo === "-1") {
      setEstadoTipo(false);
      i++;
    }
    if (i === 0) {
      props.argumentosModulos
        .filter(
          (argumento) =>
            argumento.estado === false &&
            argumento.idPregunta === props.pregunta.id
        )
        .map((argumento) => {
          i++;
        });
      if (i > 0) {
        setEstadoPregunta(false);
      } else {
        setEstadoPregunta(true);
        actualizarDatos(e);
      }
    }
  };

  const validarTipo = (value) => {
    console.log(value);
    if (value === "-1") {
      setEstadoTipo(false);
    } else {
      setEstadoTipo(true);
    }
    setTipo(value);
  };

  return (
    <React.Fragment key={props.pregunta.id}>
      <div className="center">
        <div className={classNamePregunta} style={{ width: "100%" }}>
          <div className="card-header">
            <h3 className="card-title" style={{ color: "black" }}>
              <b>{props.pregunta.id + 1 + ". " + props.pregunta.titulo}</b>
            </h3>

            <div className="card-tools">
              <span
                data-toggle="tooltip"
                title={`${i + 1} Respuestas`}
                className="badge badge-primary"
              >
                {props.argumentosModulos.map((argumento) => {
                  if (argumento.idPregunta === props.pregunta.id) {
                    i++;
                  }
                })}
                {i}
              </span>
              <button
                type="button"
                className="btn btn-tool"
                data-card-widget="collapse"
              >
                <i className="fas fa-plus" style={{ color: "GrayText" }}></i>
              </button>
            </div>
          </div>
          <div className="card-body">
            <div
              className="form-group"
              className="mt-3 container"
              style={{ width: "80%" }}
            >
              <label>Descripción Pregunta</label>
              <input
                type="text"
                className={classNameDescripcion}
                placeholder="Descripcion"
                text={descripcion}
                onChange={(e) => validarDescripcion(e.target.value)}
                disabled={props.pregunta.estado}
              />

              <label>Porcentaje Pregunta</label>
              <input
                type="number"
                className={classNamePorcentaje}
                placeholder="Porcentage"
                text={porcentajeEnModulo}
                onChange={(e) => validarPorcentaje(e.target.value)}
                disabled={props.pregunta.estado}
              />

              <label>Tipo Pregunta</label>
              <select
                onChange={(e) => validarTipo(e.target.value)}
                className={classNameTipo}
                style={{ width: "100%" }}
                disabled={props.pregunta.estado}
                text={tipo}
              >
                <option value="-1">-Seleccionar-</option>
                <option value="1">Pregunta Abierta</option>
                <option value="2">Pregunta Selección Unica</option>
                <option value="3">Pregunta Selección Multiple</option>
              </select>
            </div>
            <div>
              {props.argumentosModulos.filter(
                (argumento) => argumento.idPregunta === props.pregunta.id
              )
                ? props.argumentosModulos
                    .filter(
                      (argumento) => argumento.idPregunta === props.pregunta.id
                    )
                    .map((argumento, index) => {
                      return (
                        <CrearArgumento argumento={argumento} key={index} />
                      );
                    })
                : null}
            </div>
          </div>

          <div className="card-footer">
            <div className="container margin mt-3">
              <div
                className="d-flex justify-content-center"
                style={{ margin: "2%" }}
              >
                {props.pregunta.estado ? null : (
                  <button
                    onClick={(e) => verificarPregunta(e)}
                    className="btn btn-primary left"
                  >
                    <i className="fas fa-check"></i>
                  </button>
                )}
                {props.pregunta.estado ? (
                  <button
                    onClick={(e) => habilitarDatos(e)}
                    className="btn btn-warning"
                  >
                    <i className="fas fa-user-edit"></i>
                  </button>
                ) : null}
                <button
                  onClick={(e) => eliminarDatos(e)}
                  className="btn btn-danger right"
                >
                  <i className="fas fa-times-circle"></i>
                </button>
                {tipo === "1" ? null : (
                  <button
                    onClick={(e) => crearArgumento(e)}
                    className="btn btn-dark"
                  >
                    <i className="fas fa-plus"></i>
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    argumentosModulos: Object.values(state.argumentosModulos),
  };
};

export default connect(mapStateToProps, {
  editarPreguntaModulo,
  eliminarPreguntaModulo,
  crearArgumentoModulo,
})(CrearPregunta);
