import React, { useState, useEffect } from "react";
import * as Constants from "../../constants/global";
import axios from "axios";
import BotonIcono from "./BotonIcono";
import help from "../../assets/img/help.png";
import { cargarPreguntaModulo } from "../../actions/index";
import { connect } from "react-redux";
import CrearPregunta from "./CrearPregunta";
const FormularioPreguntas = (props) => {
  const [tituloPregunta, setTituloPregunta] = useState();

  const crearPregunta = (e) => {
    e.preventDefault();
    let params = {
      id: Constants.idPregunta,
      titulo: "",
      porcentajeEnModulo: "",
      estado: false,
    };
    Constants.setIdPregunta();
    props.cargarPreguntaModulo(params);
  };
  return (
    <React.Fragment>
      <form className="ui form mt-5 d-flex justify-content-around">
        <button onClick={(e) => crearPregunta(e)} className="btn btn-primary">
          Agregar Pregunta
        </button>
        <button
          //   onClick={props.cancelarCrearModulo}
          className="btn btn-warning"
        >
          Cancelar
        </button>
      </form>
      {/* <div classname="ui list">
        {props.preguntasModulos
          ? props.preguntasModulos.map((pregunta, index) => {
              return <CrearPregunta pregunta={pregunta} key={index} />;
            })
          : null}
      </div> */}
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    preguntasModulos: Object.values(state.preguntasModulos),
  };
};

export default connect(mapStateToProps, {
  cargarPreguntaModulo,
})(FormularioPreguntas);
