import React from "react";
import { withRouter } from "react-router-dom";
import { NavbarScreen } from "../navbar/NavbarScreen";
import SidebarScreen from "../sidebar/SidebarScreen";
import { FooterScreen } from "../footer/FooterScreen";
import { ContentScreen } from "../content/ContentScreen";

const DashboardScreen = () => {
  return (
    <div className="hold-transition sidebar-mini layout-fixed">
      {/* <NavbarScreen></NavbarScreen>
      <SidebarScreen></SidebarScreen> */}
      <ContentScreen></ContentScreen>
      {/* <FooterScreen></FooterScreen> */}
    </div>
  );
};

export default withRouter(DashboardScreen);
