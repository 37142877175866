import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import * as Constants from "../../constants/global";
import { NavbarScreen } from "../navbar/NavbarScreen";
import SidebarScreen from "../sidebar/SidebarScreen";
import { FooterScreen } from "../footer/FooterScreen";
import TablaConsolidadoScreen from "./TablaConsolidadoScreen";
import TablaConsolidadoDetallado from "./TablaConsolidadoDetallado";
import FiltroConsolidado from "./FiltroConsolidado";
import DonutChart from "./DonutChart";
import EditarModulo from "../estadisticas_modulos/EditarModulo";
import axios from "axios";
import { connect } from "react-redux";
const ConsolidadoScreen = (props) => {
  const [codModulo, setCodModulo] = useState([]);
  const [listaConsolidado, setListaConsolidado] = useState([]);
  const [listaPreguntaConsolidado, setListaPreguntaConsolidado] = useState([]);

  const cargarListaPreguntasConsolidado = async () => {
    let url =
      Constants.URL_BASE +
      Constants.SPLISTAPREGUNTACONSOLIDADO +
      "?codModulo=" +
      codModulo;
    console.log(url);
    await axios.get(url).then((res) => {
      var lista = res.data;
      console.log(lista);
      setListaPreguntaConsolidado(lista);
      console.log(res);
    });
  };

  const cargarConsolidado = async () => {
    let url =
      Constants.URL_BASE +
      Constants.SPLISTACONSOLIDADO +
      "?codModulo=" +
      codModulo;
    console.log(url);
    await axios.get(url).then((res) => {
      var listaConsolidado = res.data;
      console.log(listaConsolidado);
      setListaConsolidado(listaConsolidado);
      console.log(res);
    });
  };

  useEffect(() => {
    cargarConsolidado();
  }, []);

  useEffect(() => {
    cargarListaPreguntasConsolidado();
    cargarConsolidado();
  }, [codModulo]);

  var i = 0;
  return (
    <>
      <body class="hold-transition sidebar-mini layout-fixed">
        <div class="wraper">
          <div class="content-wrapper">
            <div class="content-header">
              <div class="container-fluid">
                <div class="row mb-2"></div>
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <div class="card card-primary">
                  <div class="card-header p-0 pt-1">
                    <ul
                      class="nav nav-tabs"
                      id="custom-tabs-one-tab"
                      role="tablist"
                    >
                      <li class="nav-item">
                        <a
                          class="nav-link active"
                          id="custom-tabs-one-home-tab"
                          data-toggle="pill"
                          href="#custom-tabs-one-home"
                          role="tab"
                          aria-controls="custom-tabs-one-home"
                          aria-selected="true"
                        >
                          Informe
                        </a>
                      </li>
                      <li class="nav-item">
                        <a
                          class="nav-link"
                          id="custom-tabs-one-profile-tab"
                          data-toggle="pill"
                          href="#custom-tabs-one-profile"
                          role="tab"
                          aria-controls="custom-tabs-one-profile"
                          aria-selected="false"
                        >
                          Informe Detallado
                        </a>
                      </li>
                    </ul>
                  </div>
                  <div class="card-body">
                    <div class="tab-content" id="custom-tabs-one-tabContent">
                      <div
                        class="tab-pane fade show active"
                        id="custom-tabs-one-home"
                        role="tabpanel"
                        aria-labelledby="custom-tabs-one-home-tab"
                      >
                        <div class="container" >
                          <FiltroConsolidado
                            setCodModulo={setCodModulo}
                          ></FiltroConsolidado>
                          {listaPreguntaConsolidado
                            ? listaPreguntaConsolidado.map((resultado) => {
                                return (
                                  <>
                                    <DonutChart
                                      resultado={resultado}
                                      listaConsolidado={listaConsolidado}
                                    ></DonutChart>
                                    <TablaConsolidadoScreen
                                      resultado={resultado}
                                      listaConsolidado={listaConsolidado}
                                    ></TablaConsolidadoScreen>
                                  </>
                                );
                              })
                            : null}
                        </div>
                      </div>
                      <div
                        class="tab-pane fade"
                        id="custom-tabs-one-profile"
                        role="tabpanel"
                        aria-labelledby="custom-tabs-one-profile-tab"
                      >
                        <FiltroConsolidado
                          setCodModulo={setCodModulo}
                        ></FiltroConsolidado>
                        <TablaConsolidadoDetallado
                          listaConsolidado={listaConsolidado}
                        ></TablaConsolidadoDetallado>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </body>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    preguntasModulos: Object.values(state.preguntasModulos),
    argumentosModulos: Object.values(state.argumentosModulos),
  };
};

export default connect(mapStateToProps, {})(withRouter(ConsolidadoScreen));
