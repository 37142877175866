import React from "react";
import { NavLink } from "react-router-dom";
import logo from "../../assets/img/logo-ramo-nuevo.png";
import person1 from "../img/user2-160x160.jpg";
import { connect } from "react-redux";

const SidebarScreen = (props) => {
  return (
    <aside className="main-sidebar sidebar-dark-primary elevation-4">
      <a href="index3.html" className="brand-link">
        <img
          src={logo}
          alt="Ramo Logo"
          className="brand-image img-circle elevation-3"
        />
        <span className="brand-text font-weight-light">
          <h4>Ramo</h4>
        </span>
      </a>

      <div className="sidebar">
        <div className="user-panel mt-3 pb-3 mb-3 d-flex">
          <div className="image">
            <img
              src={person1}
              className="img-circle elevation-2"
              alt="User Image"
            />
          </div>
          <div className="info">
            <a href="#" className="d-block">
              Jorge Santamaria
            </a>
          </div>
        </div>

        <nav className="mt-2">
          <ul
            className="nav nav-pills nav-sidebar flex-column"
            data-widget="treeview"
            role="menu"
            data-accordion="false"
          >
            {props.menus
              ? props.menus.map((menu) => {
                  return (
                    <li className="nav-item has-treeview menu-open">
                      <a className="nav-link active">
                        <i className={menu.icono}></i>
                        <p>
                          {menu.descripcion}
                          <i className="right fas fa-angle-left"></i>
                        </p>
                      </a>
                      <ul className="nav nav-treeview">
                        {props.subMenus
                          ? props.subMenus
                              .filter(
                                (subMenu) => subMenu.padreId === menu.padreId
                              )
                              .map((subMenu) => {
                                return (
                                  <li className="nav-item">
                                    <NavLink
                                    activeClassName="active"
                                      to={subMenu.url}
                                      className="nav-link"
                                      activeClassName="active"
                                      exact
                                    >
                                      <i className={subMenu.icono}></i>
                                      <p>{subMenu.descripcion}</p>
                                    </NavLink>
                                  </li>
                                );
                              })
                          : null}
                      </ul>
                    </li>
                  );
                })
              : null}
          </ul>
        </nav>
      </div>
    </aside>
  );
};

const mapStateToProps = (state) => {
  return {
    menus: Object.values(state.menus),
    subMenus: Object.values(state.subMenus),
  };
};

export default connect(mapStateToProps, {})(SidebarScreen);
