import React, { useState, useEffect } from "react";
import * as Constants from "../../constants/global";
import axios from "axios";
import help from "../../assets/img/help.png";
import FormularioModulo from "./FormularioModulo";
import FormularioPreguntas from "./FormularioPreguntas";
import BotonIcono from "./BotonIcono";
import { cargarPreguntaModulo } from "../../actions/index";
import { connect } from "react-redux";
const CrearModulo = (props) => {
  const [modulos, setModulos] = useState([]);
  const [creando, setCreando] = useState(false);
  const [descripcion, setDescripcion] = useState("");
  const [titulo, setTitulo] = useState("");
  const [creado, setCreado] = useState(false);
  const [estadoTitulo, setEstadoTitulo] = useState(true);
  const [estadoDescripcion, setEstadoDescripcion] = useState(true);
  const [fechaInicial, setFechaInicial] = useState(new Date());
  const [fechaFinal, setFechaFinal] = useState(new Date());

  // const cargarModulos = async () => {
  //   let url = Constants.URL_BASE + Constants.SPMODULOS;
  //   console.log(url);
  //   await axios.get(url).then((res) => {
  //     var listaModulos = res.data;
  //     console.log(listaModulos);
  //     //   setModulos(listaModulos);

  //     setModulos(listaModulos);
  //   });
  // };

  // useEffect(() => {
  //   cargarModulos();
  // }, []);

  const creandoModulo = () => {
    setCreando(true);
  };

  const cancelarCrearModulo = () => {
    setCreando(false);
  };

  const validarTitulo = (value) => {
    if (value === "" || value === undefined) {
      setEstadoTitulo(false);
    } else {
      setEstadoTitulo(true);
    }
    setTitulo(value);
  };

  const validarDescripcion = (value) => {
    if (value === "" || value === undefined) {
      setEstadoDescripcion(false);
    } else {
      setEstadoDescripcion(true);
    }
    setDescripcion(value);
  };

  const crearModulo = (e) => {
    e.preventDefault();
    var i = 0;
    if (titulo === "" || titulo === undefined) {
      setEstadoTitulo(false);
      i++;
    }
    if (descripcion === "" || descripcion === undefined) {
      setEstadoDescripcion(false);
      i++;
    }
    if (i === 0) {
      setCreado(true);
      setCreando(false);
      localStorage.setItem("titulo", titulo);
      localStorage.setItem("descripcion", descripcion);
    }
  };

  return (
    <React.Fragment>
      <div className="card card-primary mt-3">
        <div className="card-header blue">
          <h3 className="card-title">Creación Modulo</h3>
        </div>
        <div className="navbar principal"></div>
        <div className="contenedor">
          <div className="text-center">
            {creando || creado ? null : (
              <button
                className="btn btn-success"
                onClick={(e) => creandoModulo(e)}
              >
                Crear modulo
              </button>
            )}
            {creando ? (
              <FormularioModulo
                cancelarCrearModulo={cancelarCrearModulo}
                crearModulo={crearModulo}
                validarDescripcion={validarDescripcion}
                validarTitulo={validarTitulo}
                titulo={titulo}
                descripcion={descripcion}
                estadoTitulo={estadoTitulo}
                estadoDescripcion={estadoDescripcion}
                fechaFinal={fechaFinal}
                fechaInicial={fechaInicial}
                setFechaFinal={setFechaFinal}
                setFechaInicial={setFechaInicial}
              />
            ) : null}
            {creado ? <FormularioPreguntas /> : null}
          </div>
          <div className="divCentro">
            <br />
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default CrearModulo;
