import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import * as Constants from "../../constants/global";
import FiltroRoles from "./FiltroRoles";
import axios from "axios";
import { connect } from "react-redux";
import "./GestionMenu.scss";
import $ from "jquery";
import { toast } from "react-toastify";

const GestionMenu = (props) => {
  const [rol, setRol] = useState("-1");
  const [listaMenuRoles, setListaMenuRoles] = useState([]);
  const [estadoCheck, setEstadoCheck] = useState(false);

  const actualizarMenuRol = async (codMenu) => {
    console.log("si");
    let url =
      Constants.URL_BASE +
      Constants.SPUPDATEMENUSROL +
      "?codigoModulo=" +
      codMenu +
      "&jerarquia=" +
      rol;
    console.log(url);
    setEstadoCheck(true);
    await axios.put(url).then((res) => {
      if (res.data === "OK") {
        toast.success("Se actualizo correctamente!");
        cargarMenuRoles();
        activarChecks();
      } else {
        toast.error("Error en la actualización!");
        activarChecks();
      }
      console.log(res);
    });
  };

  const activarChecks = () => {
    setEstadoCheck(false);
  };

  const lista = listaMenuRoles.map((menuRoles) => {
    return (
      <li class="list-group-item">
        <table>
          <tr>
            <td>
              <input
                class="form-check-input me-1 check-box"
                type="checkbox"
                value={menuRoles.codigo}
                aria-label="..."
                checked={menuRoles.activo === 1 ? true : false}
                onClick={(e) => actualizarMenuRol(e.target.value)}
              />
            </td>
            <td>
              <h5>{menuRoles.descripcion}</h5>
            </td>
          </tr>
        </table>
      </li>
    );
  });

  const cargarMenuRoles = async () => {
    console.log(rol);
    let url = Constants.URL_BASE + Constants.SPLISTAMENUSROL + "?codRol=" + rol;
    console.log(url);
    await axios.get(url).then((res) => {
      var listaConsolidado = res.data;
      console.log(listaConsolidado);
      setListaMenuRoles(listaConsolidado);
      console.log(res);
    });
  };

  useEffect(() => {
    cargarMenuRoles();
  }, []);

  useEffect(() => {
    cargarMenuRoles();
  }, [rol]);

  var i = 0;
  return (
    <>
      <body class="hold-transition sidebar-mini layout-fixed">
        <div class="wraper">
          <div class="content-wrapper">
            <div class="content-header">
              <div class="container-fluid">
                <div class="row mb-2"></div>
              </div>
            </div>
            <section class="content">
              <div class="row center">
                <FiltroRoles setRol={setRol}></FiltroRoles>
                <div class="col-md-12">
                  <div class="card card-primary">
                    <div class="card-header">
                      <h3 class="card-title">General</h3>

                      <div class="card-tools">
                        <button
                          type="button"
                          class="btn btn-tool"
                          data-card-widget="collapse"
                          data-toggle="tooltip"
                          title="Collapse"
                          disabled={estadoCheck}
                        >
                          <i class="fas fa-minus"></i>
                        </button>
                      </div>
                    </div>
                    <div class="card-body">
                      <div style={{ margin: "2%" }}>
                        <ul class="list-group">{lista ? lista : null}</ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
          {/* <FooterScreen></FooterScreen> */}
        </div>
      </body>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    preguntasModulos: Object.values(state.preguntasModulos),
    argumentosModulos: Object.values(state.argumentosModulos),
  };
};

export default connect(mapStateToProps, {})(withRouter(GestionMenu));
