import _ from "lodash";
import initialState from "./initialState";

import {
  CARGAR_PREGUNTA_MODULO,
  EDITAR_PREGUNTA_MODULO,
  ELIMINAR_PREGUNTA_MODULO,
  LIMPIAR_PREGUNTA_MODULO,
  CARGAR_PREGUNTA_MODULO_EDIT,
} from "../../constants/global";
export default (state = initialState.preguntas, action) => {
  switch (action.type) {
    case CARGAR_PREGUNTA_MODULO:
      return [...state, action.payload];
    case EDITAR_PREGUNTA_MODULO:
      console.log(action.payload);
      state = state.map((content, i) =>
        content.id === action.payload.id ? action.payload : content
      );
      return state;
    case ELIMINAR_PREGUNTA_MODULO:
      state = state.filter((item) => item.id !== action.payload.id);
      return state;
    case LIMPIAR_PREGUNTA_MODULO:
      state = [];
      return state;
    case CARGAR_PREGUNTA_MODULO_EDIT:
      return [...state, action.payload];
    default:
      return state;
  }
};
