import React from "react";
import { Modal } from "react-bootstrap";
import LogoWhite from "../../../assets/img/logo-ramo-nuevo.png";

import "../basic-modal/BasicModal.scss";

export default function BasicModal(props) {
  const { show, setShow, children } = props;
  return (
    <Modal show={show} onHide={() => setShow} centered size="lg">
      <Modal.Header>
        <Modal.Title>
          <img className="img-modal" src={LogoWhite} alt="twittor"></img>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>{children}</Modal.Body>
    </Modal>
  );
}
