import React, { useEffect } from "react";
import { withRouter } from "react-router-dom";
import * as Constants from "../../constants/global";
import { NavbarScreen } from "../navbar/NavbarScreen";
import SidebarScreen from "../sidebar/SidebarScreen";
import { FooterScreen } from "../footer/FooterScreen";
import axios from "axios";
import CrearModulo from "../../components/survey/CrearModulo";
import { Link, NavLink, useHistory } from "react-router-dom";
import { connect } from "react-redux";
import CrearPregunta from "./CrearPregunta";
import { limpiarDatos } from "../../actions/index";

const SurveyScreen = (props) => {
  const finalizarModulo = async () => {
    console.log(props.parametrosModulos);
    await agregarModulo()
      .then(await agregarPregunta())
      .then(await agregarParametro())
      .then(await consolidarModulo().then(window.location.reload()));
  };

  const consolidarModulo = async () => {
    let url = Constants.URL_BASE + Constants.SPCONSOLIDARMOD;
    await axios.post(url).then((res) => {
      console.log(res.data);
    });
  };

  const agregarPregunta = async () => {
    var listaPreguntas = props.preguntasModulos.map((pregunta) => {
      return pregunta;
    });
    console.log(listaPreguntas);
    let url = Constants.URL_BASE + Constants.SPINSERTARPREGMOD;
    await axios.post(url, { preguntas: listaPreguntas }).then((res) => {
      console.log(res.data);
    });
  };

  useEffect(() => {
    props.limpiarDatos();
  }, []);

  const agregarModulo = async () => {
    var data = {
      titulo: localStorage.getItem("titulo"),
      descripcion: localStorage.getItem("descripcion"),
    };
    console.log(data);
    let url = Constants.URL_BASE + Constants.SPINSERTARMOD;
    await axios.post(url, data).then((res) => {
      console.log(res.data);
    });
  };

  const agregarParametro = async () => {
    var listaParametros = props.argumentosModulos.map((parametro) => {
      return parametro;
    });
    console.log(listaParametros);
    let url = Constants.URL_BASE + Constants.SPINSERTARPARMOD;
    await axios.post(url, { parametros: listaParametros }).then((res) => {
      console.log(res.data);
    });
  };

  var i = 0;
  return (
    <>
      <body className="hold-transition sidebar-mini layout-fixed">
        <div className="wraper">
          <div class="content-wrapper">
            <div class="content-header">
              <div class="container-fluid">
                <div class="row mb-2"></div>
              </div>
            </div>
            <div className="container" >
              <CrearModulo></CrearModulo>
              <div className="container ">
                <div class="ui list">
                  {props.preguntasModulos
                    ? props.preguntasModulos.map((pregunta, index) => {
                        return (
                          <CrearPregunta pregunta={pregunta} key={index} />
                        );
                      })
                    : null}
                </div>
              </div>
              <div className="container center" style={{ width: "100%" }}>
                <div
                  className="card card-primary center"
                  style={{ width: "20%" }}
                >
                  {props.preguntasModulos !== []
                    ? props.preguntasModulos.map((pregunta, index) => {
                        if (pregunta.estado === false) {
                          i++;
                        }
                      })
                    : null}
                  {i > 0 || props.preguntasModulos.length === 0 ? null : (
                    <button
                      className="btn btn-danger"
                      onClick={finalizarModulo}
                    >
                      Finalizar
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </body>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    preguntasModulos: Object.values(state.preguntasModulos),
    argumentosModulos: Object.values(state.argumentosModulos),
  };
};

export default connect(mapStateToProps, { limpiarDatos })(
  withRouter(SurveyScreen)
);
