import React, { useEffect } from "react";
import { withRouter } from "react-router-dom";
import * as Constants from "../../constants/global";
import { NavbarScreen } from "../navbar/NavbarScreen";
import SidebarScreen from "../sidebar/SidebarScreen";
import { FooterScreen } from "../footer/FooterScreen";
import axios from "axios";
import CrearModulo from "../survey/CrearModulo";
import { Link, NavLink, useHistory } from "react-router-dom";
import { connect } from "react-redux";
import CrearPreguntaEdit from "./CrearPreguntaEdit";
import { limpiarDatos } from "../../actions/index";
import FormularioPreguntasEdit from "./FormularioPreguntasEdit";
const SurveyEditScreen = (props) => {
  const finalizarModulo = () => {
    console.log(props.parametrosModulos);
    agregarModulo();
    props.preguntasModulos.map((pregunta) => {
      agregarPregunta(pregunta);
    });
    props.argumentosModulos.map((parametro) => {
      console.log(parametro);
      agregarParametro(parametro);
    });
    // consolidarModulo();
    window.location.reload();
  };

  useEffect(() => {
    props.limpiarDatos();
  }, []);

  const agregarPregunta = async (pregunta) => {
    let url = Constants.URL_BASE + Constants.SPINSERTARPREGMOD;
    await axios.post(url, pregunta).then((res) => {
      console.log(res.data);
    });
  };

  const agregarModulo = async () => {
    var data = {
      titulo: localStorage.getItem("titulo"),
      descripcion: localStorage.getItem("descripcion"),
      fechainicial: localStorage.getItem("fechainicial"),
      fechaFinal: localStorage.getItem("fechaFinal"),
    };
    console.log(data);
    let url = Constants.URL_BASE + Constants.SPINSERTARMOD;
    await axios.post(url, data).then((res) => {
      console.log(res.data);
    });
  };

  const agregarParametro = async (parametro) => {
    let url = Constants.URL_BASE + Constants.SPINSERTARPARMOD;
    await axios.post(url, parametro).then((res) => {
      console.log(res.data);
    });
  };

  var i = 0;
  return (
    <>
      <body className="hold-transition sidebar-mini layout-fixed">
        <div className="wraper">
          <div class="content-wrapper">
            <div class="content-header">
              <div class="container-fluid">
                <div class="row mb-2"></div>
              </div>
            </div>
            <div className="container">
              <FormularioPreguntasEdit />
              <div className="container ">
                <div class="ui list">
                  {props.preguntasModulos
                    ? props.preguntasModulos.map((pregunta, index) => {
                        return (
                          <CrearPreguntaEdit pregunta={pregunta} key={index} />
                        );
                      })
                    : null}
                </div>
              </div>
              <div className="container center" style={{ width: "100%" }}>
                <div
                  className="card card-primary center"
                  style={{ width: "20%" }}
                >
                </div>
              </div>
            </div>
          </div>
        </div>
      </body>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    preguntasModulos: Object.values(state.preguntasModulos),
    argumentosModulos: Object.values(state.argumentosModulos),
  };
};

export default connect(mapStateToProps, { limpiarDatos })(
  withRouter(SurveyEditScreen)
);
