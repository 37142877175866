import _ from "lodash";
import initialState from "./initialState";

import {
  CARGAR_SUB_MENUS,
  LIMPIAR_SUBMENU_MODULO,
} from "../../constants/global";
export default (state = initialState.submenus, action) => {
  switch (action.type) {
    case CARGAR_SUB_MENUS:
      console.log(action.payload);
      return [...state, action.payload];
    case LIMPIAR_SUBMENU_MODULO:
      state = [];
      return state;
    default:
      return state;
  }
};
