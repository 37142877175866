import { combineReducers } from "redux";
import preguntasModuloReducer from "../reducers/preguntasModuloReducer";
import argumentosModuloReducer from "../reducers/argumentosModuloReducer";
import menusReducerReducer from "../reducers/menusReducer";
import subMenusReducer from "../reducers/subMenusReducer";
import authReducer from "./authReducer";
export default combineReducers({
  preguntasModulos: preguntasModuloReducer,
  argumentosModulos: argumentosModuloReducer,
  menus: menusReducerReducer,
  subMenus: subMenusReducer,
  auth: authReducer,
});
