import * as Constants from "../../constants/global";
import axios from "axios";
import "./estadisticas_modulos.scss";
const TablaScreen = (props) => {
  const edicionModulo = (modulo) => {
    props.setShowModal(true);
    localStorage.setItem("codigoEditarModulo", modulo.codigo);
    console.log(modulo.codigo);
    props.setModuloCargado(modulo);
    props.abrirEditarModulo();
  };

  const eliminarModulo = async (modulo) => {
    var data = {
      codigo: `${modulo.codigo}`,
      titulo: modulo.titulo,
      descripcion: modulo.descripcion,
      fechainicial: modulo.fechaInicial,
      fechaFinal: modulo.fechaFinal,
    };
    let url = Constants.URL_BASE + Constants.SPELIMINARMOD;
    await axios.post(url, data).then((res) => {
      window.location.reload();
    });
  };
  return (
    <>
      <div class="card-header blue">
        <h3 class="card-title">Lista Modulos</h3>
      </div>
      {/* <section class="content"> */}
      {/* <div class="row"> */}
      {/* <div class="col-12"> */}
      <div class="card">
        <div class="card-body">
          <table
            id="example2"
            class="table table-bordered table-hover"
            style={{ tableLayout: "fixed" }}
          >
            <tbody>
              <th>Codigo</th>
              <th>Titulo</th>
              <th>Descripción</th>
              <th>Fecha Inicial</th>
              <th>Fecha Final</th>
              <th>Activo</th>
              <th>Editar</th>
              <th>Eliminar</th>
              {props.listaModulos
                ? props.listaModulos.map((modulo) => {
                    return (
                      <tr>
                        <td>{modulo.codigo}</td>
                        <td>{modulo.titulo}</td>
                        <td>{modulo.descripcion}</td>
                        <td>{modulo.fechaInicial}</td>
                        <td>{modulo.fechaFinal}</td>
                        <td>{modulo.activo === 1 ? "Si" : "No"}</td>
                        <td>
                          <i
                            onClick={(e) => edicionModulo(modulo)}
                            className="fas fa-user-edit icono-editar"
                          ></i>
                        </td>
                        <td>
                          <i
                            onClick={(e) => eliminarModulo(modulo)}
                            className="fas fa-times-circle icono-eliminar"
                          ></i>
                        </td>
                      </tr>
                    );
                  })
                : null}
            </tbody>
          </table>
        </div>
      </div>
      {/* </div> */}
      {/* </div> */}
      {/* </section> */}
    </>
  );
};

export default TablaScreen;
