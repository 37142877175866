import React from "react";
import { AppRouter } from "./routers/AppRouter";
import { connect } from "react-redux";


const App = (props) => {
  return <AppRouter auth={props.auth}></AppRouter>;
};

const mapStateToProps = (state) => {
  return {
    auth: state.auth.isSignedIn,
  };
};

export default connect(mapStateToProps, {})(App);
