import React, { useState, useEffect } from "react";
import * as Constants from "../../constants/global";
import axios from "axios";
import Auth from "../helpers/Auth";
import fondo from "../../assets/img/fondo-ramo.jpeg";
import logo from "../../assets/img/logo-ramo-nuevo.png";
import {
  cargarMenus,
  cargarSubMenus,
  limpiarMenusSubMenus,
  signIn,
  signOut,
} from "../../actions/index";
import { connect } from "react-redux";
const LoginScreen = (props) => {
  const [usuario, setUsuario] = useState("");
  const [password, setPassword] = useState("");

  useEffect(() => {
    props.signOut();
  }, []);

  const intentarLogeo = (e) => {
    e.preventDefault();
    let url =
      Constants.URL_BASE +
      Constants.SPLOGIN +
      "?usuario=" +
      usuario +
      "&password=" +
      password;

    console.log(url);

    let data = {
      usuario: usuario.trim(),
      clave: password.trim(),
    };

    console.log(data);

    axios.get(url, data).then((resLogin) => {
      if (resLogin.data.resultado == "OK") {
        props.limpiarMenusSubMenus();
        props.cargarMenus();
        props.cargarSubMenus();
        props.signIn(resLogin.data);
        props.history.push("/dashboard");
      }
    });
  };

  return (
    <div
      className="hold-transition login-page"
      style={{
        backgroundImage: `url(${fondo})`,
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
      }}
    >
      <div className="login-box">
        <div className="card card-outline card-primary">
          <div className="card-header text-center">
            <a href="../../index2.html" className="h1">
              <img src={logo} width="80%" />
            </a>
          </div>
          <div className="card-body">
            <p className="login-box-msg">Inicio de Sesión</p>

            <div>
              <div className="input-group mb-3">
                <input
                  value={usuario}
                  onChange={(e) => setUsuario(e.target.value)}
                  type="text"
                  className="form-control"
                  placeholder="Email"
                />
                <div className="input-group-append">
                  <div className="input-group-text">
                    <span className="fas fa-envelope"></span>
                  </div>
                </div>
              </div>
              <div className="input-group mb-3">
                <input
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  type="password"
                  className="form-control"
                  placeholder="Password"
                />
                <div className="input-group-append">
                  <div className="input-group-text">
                    <span className="fas fa-lock"></span>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-8">
                  <div className="icheck-primary">
                    <input type="checkbox" id="remember" />
                    <label htmlFor="remember">Recordarme</label>
                  </div>
                </div>
                <div className="col-4">
                  <button
                    onClick={(e) => intentarLogeo(e)}
                    className="btn btn-primary btn-block"
                  >
                    Ingresar
                  </button>
                </div>
              </div>
            </div>

            {/* <div className="social-auth-links text-center mt-2 mb-3">
                <a href="#" className="btn btn-block btn-primary">
                  <i className="fab fa-facebook mr-2"></i> Sign in using Facebook
                </a>
                <a href="#" className="btn btn-block btn-danger">
                  <i className="fab fa-google-plus mr-2"></i> Sign in using Google+
                </a>
              </div> */}
            <p className="mb-1">
              <a href="forgot-password.html">Olvide mi contraseña</a>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};
export default connect(null, {
  cargarSubMenus,
  cargarMenus,
  limpiarMenusSubMenus,
  signIn,
  signOut,
})(LoginScreen);
