import React from "react";
import { Switch, Route, BrowserRouter } from "react-router-dom";
import history from "../history";
import LoginScreen from "../components/auth/LoginScreen";
import DashboardScreen from "../components/dashboard/DashboardScreen";
import SurveyScreen from "../components/survey/SurveyScreen";
import SurveyEditScreen from "../components/survey-edit/SurveyEditScreen";
import ListaModulosScreen from "../components/estadisticas_modulos/ListaModulosScreen";
import ConsolidadoScreen from "../components/consolidado/ConsolidadoScreen";
import GestionMenu from "../components/administracion_movil/GestionMenu";
import { NavbarScreen } from "../components/navbar/NavbarScreen";
import { FooterScreen } from "../components/footer/FooterScreen";
import SidebarScreen from "../components/sidebar/SidebarScreen";
import { ToastContainer } from "react-toastify";
import GestionMenuClientes from "../components/gestion_menu_clientes/GestionMenuClientes";
export const AppRouter = (props) => {
  console.log(props.auth);
  return (
    <>
      <BrowserRouter history={history}>
        <body className="hold-transition sidebar-mini layout-fixed">
          <div class="wraper">
            {props.auth ? (
              <>
                <NavbarScreen /> <SidebarScreen />{" "}
              </>
            ) : null}
            <Switch>
              <Route exact path="/dashboard" component={DashboardScreen} />
              <Route exact path="/survey" component={SurveyScreen} />
              <Route exact path="/" component={LoginScreen} />
              <Route exact path="/listaModulo" component={ListaModulosScreen} />
              <Route exact path="/surveyEdit" component={SurveyEditScreen} />
              <Route exact path="/consolidado" component={ConsolidadoScreen} />
              <Route exact path="/gestionMenu" component={GestionMenu} />
              <Route exact path="/gestionMenuClientes" component={GestionMenuClientes} />
            </Switch>
            {/* {props.auth ? (
          <> */}
            <FooterScreen />
            {/* </>
        ) : null} */}
          </div>
        </body>
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnVisibilityChange
          draggable
          pauseOnHover
        />
      </BrowserRouter>
    </>
  );
};
