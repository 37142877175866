import React from 'react'

export const FooterScreen = () => {
    return (
       <div>
        <footer className="main-footer">
        <strong>Copyright &copy; 2014-2019 <a href="https://www.ramo.com.co/">Ramo</a>.</strong>
             All rights reserved.
        </footer>
         <aside className="control-sidebar control-sidebar-dark"></aside>
       </div>
    )
}
