import $, { data } from "jquery";
import { Doughnut } from "react-chartjs-2";

import React from "react";

const DonutChart = (props) => {
  var labels = props.listaConsolidado
    .filter((lista) => lista.codigoPregunta === props.resultado.codigoPregunta)
    .map((modulo) => {
      return modulo.respuesta;
    });

  var datas = props.listaConsolidado
    .filter((lista) => lista.codigoPregunta === props.resultado.codigoPregunta)
    .map((modulo) => {
      return modulo.cantidad;
    });

  const state = {
    labels: labels,
    datasets: [
      {
        label: "Rainfall",
        fill: false,
        lineTension: 0.5,
        backgroundColor: "rgba(75,192,192,1)",
        borderColor: "rgba(0,0,0,1)",
        borderWidth: 2,
        data: datas,
      },
    ],
  };

  return (
    <div class="card card-danger collapsed-card">
      <div class="card-header">
        <h3 class="card-title" style={{ color: "black" }}>
          {props.resultado.descripcion}
        </h3>

        <div class="card-tools">
          <button
            type="button"
            class="btn btn-tool"
            data-card-widget="collapse"
          >
            <i class="fas fa-plus" style={{color:'GrayText'}}></i>
          </button>
          {/* <button type="button" class="btn btn-tool" data-card-widget="remove">
            <i class="fas fa-times"></i>
          </button> */}
        </div>
      </div>
      <div class="card-body" style={{ width: "40%", margin: "auto" }}>
        <Doughnut
          data={state}
          options={{
            title: {
              display: true,
              text: "Average Rainfall per month",
              fontSize: 20,
              color: "black",
            },
            legend: {
              display: true,
              position: "right",
            },
          }}
        />
      </div>
    </div>
  );
};
export default DonutChart;
