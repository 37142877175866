import { SIGN_OUT, SIGN_IN } from "../../constants/global";
import initialState from "./initialState";

export default (state = initialState.auth, action) => {
  switch (action.type) {
    case SIGN_IN:
      return {
        ...state,
        isSignedIn: true,
        userId: action.payload.codigoUsuario,
        userName: action.payload.nombre,
      };
    case SIGN_OUT:
      return { ...state, isSignedIn: false, userId: null, userName: null };
    default:
      return state;
  }
};
