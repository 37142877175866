import _ from "lodash";
import initialState from "./initialState";

import { CARGAR_MENUS, LIMPIAR_MENU_MODULO } from "../../constants/global";
export default (state = initialState.menus, action) => {
  switch (action.type) {
    case CARGAR_MENUS:
      console.log(action.payload);
      return [...state, action.payload];
    case LIMPIAR_MENU_MODULO:
      state = [];
      return state;
    default:
      return state;
  }
};
