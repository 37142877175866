import React, { useState } from "react";
// import DatePicker from "react-date-picker";
const FormularioModulo = (props) => {

  const classNameTitle = `form-control ${props.estadoTitulo ? "" : "error"}`;
  const classNameDescripcion = `form-control ${props.estadoDescripcion ? "" : "error"}`;
  return (
    <React.Fragment>
      <div className="container center">
        <div className="card card-primary" style={{width:'100%'}}>
          {/* <div className="card-header blue">
            <h3 className="card-title">Crear pregunta</h3>
          </div> */}
          <form>
            <div className="card-body">
              <div className="form-group">
                <label className="d-flex justify-content-start">Titulo</label>
                <input
                  type="text"
                  className={classNameTitle}
                  placeholder="Escribe el titulo"
                  text={props.titulo}
                  onChange={(e) => props.validarTitulo(e.target.value)}
                />
              </div>
              <div className="form-group">
                <label className="d-flex justify-content-start">
                  Descripcion
                </label>
                <input
                  type="text"
                  className={classNameDescripcion}
                  placeholder="Escribe la descripcion"
                  text={props.descripcion}
                  onChange={(e)=>props.validarDescripcion(e.target.value)}
                />
              </div>
              {/* <div className="form-group">
                <label className="d-flex justify-content-start">
                  Fecha Inicial
                </label>
                <DatePicker
                  onChange={props.setFechaInicial}
                  value={props.fechaInicial}
                  style={{ width: "80%" }}
                />
              </div>
              <div className="form-group">
                <label className="d-flex justify-content-start">
                  Fecha Final
                </label>
                <DatePicker
                  onChange={props.setFechaFinal}
                  value={props.fechaFinal}
                  style={{ width: "80%" }}
                />
              </div> */}
            </div>
            <div className="card-footer d-flex justify-content-around">
              <button
                type="submit"
                className="btn btn-primary center"
                onClick={props.crearModulo}
              >
                Crear
              </button>
              <button
                type="submit"
                className="btn btn-danger center"
                onClick={props.cancelarCrearModulo}
              >
                Cancelar
              </button>
            </div>
          </form>
        </div>
      </div>
    </React.Fragment>
  );
};

export default FormularioModulo;
