import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import * as Constants from "../../constants/global";
import { NavbarScreen } from "../navbar/NavbarScreen";
import SidebarScreen from "../sidebar/SidebarScreen";
import { FooterScreen } from "../footer/FooterScreen";
import TablaScreen from "../../components/estadisticas_modulos/TablaScreen";
import EditarModulo from "../../components/estadisticas_modulos/EditarModulo";
import axios from "axios";
import { connect } from "react-redux";
import BasicModal from "../modals/basic-modal/BasicModal";
const ListaModulosScreen = (props) => {
  const [listaModulos, setListaModulos] = useState([]);
  const [editarModulo, setEditarModulo] = useState(false);
  const [moduloCargado, setModuloCargado] = useState();
  const [showModal, setShowModal] = useState(false);
  const [contentModal, setContentModal] = useState(null);
  const finalizarModulo = () => {
    console.log(props.parametrosModulos);
    agregarModulo();
    props.preguntasModulos.map((pregunta) => {
      agregarPregunta(pregunta);
    });
    props.argumentosModulos.map((parametro) => {
      console.log(parametro);
      agregarParametro(parametro);
    });
    window.location.reload();
  };

  const cargarModulos = async () => {
    let url = Constants.URL_BASE + Constants.SPMODULOS;
    console.log(url);
    await axios.get(url).then((res) => {
      var listaModulos = res.data;
      console.log(listaModulos);
      setListaModulos(listaModulos);
      console.log(res);
    });
  };

  useEffect(() => {
    cargarModulos();
  }, []);

  const agregarPregunta = async (pregunta) => {
    let url = Constants.URL_BASE + Constants.SPINSERTARPREGMOD;
    await axios.post(url, pregunta).then((res) => {
      console.log(res.data);
    });
  };

  const agregarModulo = async () => {
    var data = {
      titulo: localStorage.getItem("titulo"),
      descripcion: localStorage.getItem("descripcion"),
      fechainicial: localStorage.getItem("fechainicial"),
      fechaFinal: localStorage.getItem("fechaFinal"),
    };
    console.log(data);
    let url = Constants.URL_BASE + Constants.SPINSERTARMOD;
    await axios.post(url, data).then((res) => {
      console.log(res.data);
    });
  };

  const agregarParametro = async (parametro) => {
    let url = Constants.URL_BASE + Constants.SPINSERTARPARMOD;
    await axios.post(url, parametro).then((res) => {
      console.log(res.data);
    });
  };

  const abrirEditarModulo = () => {
    setEditarModulo(true);
  };

  var i = 0;
  return (
    <>
      <body class="hold-transition sidebar-mini layout-fixed">
        <div class="wraper">
          <div class="content-wrapper">
            <div class="content-header">
              <div class="container-fluid">
                <div class="row mb-2"></div>
              </div>
            </div>
            {editarModulo === true && moduloCargado ? (
              <div class="container"></div>
            ) : null}

            <div class="container" style={{ width: "100%" }}>
              <TablaScreen
                abrirEditarModulo={abrirEditarModulo}
                listaModulos={listaModulos}
                setModuloCargado={setModuloCargado}
                setShowModal={setShowModal}
              ></TablaScreen>
            </div>
          </div>
        </div>
      </body>
      <BasicModal show={showModal} setShowModal={setShowModal}>
        <EditarModulo
          listaModulos={listaModulos}
          moduloCargado={moduloCargado}
        ></EditarModulo>
      </BasicModal>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    preguntasModulos: Object.values(state.preguntasModulos),
    argumentosModulos: Object.values(state.argumentosModulos),
  };
};

export default connect(mapStateToProps, {})(withRouter(ListaModulosScreen));
