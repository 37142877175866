import React, { useState, useEffect } from "react";
import FormularioModuloEditar from "../../components/estadisticas_modulos/FormularioModuloEditar";
import FormularioPreguntas from "../../components/survey/FormularioPreguntas";
import * as Constants from "../../constants/global";
import axios from "axios";
const EditarModulo = (props) => {
  const [modulos, setModulos] = useState([]);
  const [creando, setCreando] = useState(false);
  const [descripcion, setDescripcion] = useState();
  const [titulo, setTitulo] = useState();
  const [creado, setCreado] = useState(false);
  const [estadoTitulo, setEstadoTitulo] = useState(true);
  const [estadoDescripcion, setEstadoDescripcion] = useState(true);
  const [fechaInicial, setFechaInicial] = useState(new Date());
  const [fechaFinal, setFechaFinal] = useState(new Date());

  const cargarModulo = async () => {
    setTitulo(props.moduloCargado.titulo);
    setDescripcion(props.moduloCargado.descripcion);
    setFechaInicial(Constants.convert112(props.moduloCargado.fechaInicial));
    setFechaFinal(Constants.convert112(props.moduloCargado.fechaFinal));
  };

  useEffect(() => {
    cargarModulo();
  }, [props.moduloCargado]);

  const creandoModulo = () => {
    setCreando(true);
  };

  const cancelarCrearModulo = () => {
    setCreando(false);
  };

  const validarTitulo = (value) => {
    if (value === "" || value === undefined) {
      setEstadoTitulo(false);
    } else {
      setEstadoTitulo(true);
    }
    setTitulo(value);
  };

  const validarDescripcion = (value) => {
    if (value === "" || value === undefined) {
      setEstadoDescripcion(false);
    } else {
      setEstadoDescripcion(true);
    }
    setDescripcion(value);
  };

  const crearModulo = (e) => {
    e.preventDefault();
    var i = 0;
    if (titulo === "" || titulo === undefined) {
      setEstadoTitulo(false);
      i++;
    }
    if (descripcion === "" || descripcion === undefined) {
      setEstadoDescripcion(false);
      i++;
    }
    if (i === 0) {
      //   setCreado(true);
      //   setCreando(false);
      ejecutarEdicionModulo();
    }
  };

  const ejecutarEdicionModulo = async () => {
    var data = {
      codigo: `${props.moduloCargado.codigo}`,
      titulo: titulo,
      descripcion: descripcion,
      fechainicial: Intl.DateTimeFormat("en-US", {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
      })
        .format(fechaInicial)
        .substr(0, 10),
      fechaFinal: Intl.DateTimeFormat("en-US", {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
      })
        .format(fechaFinal)
        .substr(0, 10),
    };
    console.log(data);
    let url = Constants.URL_BASE + Constants.SPEDITARMOD;
    await axios.post(url, data).then((res) => {
      window.location.reload();
    });
  };

  return (
    <React.Fragment>
      <>
        <div className="contenedor">
          <div className="text-center">
            <FormularioModuloEditar
              cancelarCrearModulo={cancelarCrearModulo}
              crearModulo={crearModulo}
              validarDescripcion={validarDescripcion}
              validarTitulo={validarTitulo}
              titulo={titulo}
              descripcion={descripcion}
              estadoTitulo={estadoTitulo}
              estadoDescripcion={estadoDescripcion}
              fechaFinal={fechaFinal}
              fechaInicial={fechaInicial}
              setFechaFinal={setFechaFinal}
              setFechaInicial={setFechaInicial}
              moduloCargado={props.moduloCargado}
            />

            {creado ? <FormularioPreguntas /> : null}
          </div>
          <div className="divCentro">
            <br />
          </div>
        </div>
      </>
    </React.Fragment>
  );
};

export default EditarModulo;
